import React, { useState, useEffect } from 'react'
import Item from './Item'
import { Button } from 'antd';

const Items = (props) => {
  const [data, setData] = useState({recommendations: []});
  const getData = async() => {
    const urlToFetch = props.url;
    const response = await fetch(urlToFetch, {
      method: "POST",
      body: JSON.stringify(props.options),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    
    const data = await response.json();
    if (urlToFetch === "https://personalize.fullstackdata.workers.dev/get_recommendations") {
      data['recommendations'].sort(() => (Math.random() > .5) ? 1 : -1);
      data['recommendations'] = data['recommendations'].slice(0,5);
    }
    setData(data);
  };

  useEffect(() => {
    getData();
  }, []);
  
  return (
    <>
      {data.recommendations.map((item) => <Item key={item.url} title={item.title} leadImgUrl={item.leadImgUrl} url={item.url}/>)}
      <Button type="primary" onClick={getData}>Refresh</Button>
    </>
  )
}

export default Items
