import './App.css';
import Main from './Main';
import URL from './URL';
import { BrowserRouter, Route } from 'react-router-dom'


function App() {
  return (
    <BrowserRouter>
      <Route exact path="/" component={Main} />
      <Route path="/url/:pagePath+" component={URL} />
    </BrowserRouter>
  );
}

export default App;
