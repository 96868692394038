import React from 'react';
import { Layout, Divider, Typography } from 'antd';
import NavBar from './Components/NavBar';
import Items from './Components/Items';
import "./App.css";

function Main() {
  const { Content } = Layout;
  const { Title, Text } = Typography;
  const getStorage = function(key) {
    /**
    * Returns a value from local storage if it exists, and null if it does not
    */
    return localStorage.getItem(key);
  }
  const uuid = getStorage("fsd_uuid_fsd-reader");
  const randomNum = parseInt(1000*Math.random());

  return (
    <Layout className="layout" style={{"maxWidth": 500, "margin": "0 auto", "height": "100%"}}>
      <NavBar />
      <Content style={{ padding: '10px' }}>
        <Title level={3}>Recommended for you</Title>
        <Items key={randomNum} url={"https://personalize.fullstackdata.workers.dev/get_recommendations"} options={{reqType: "user_related", uuid: uuid}}/>
        
        <Divider />
        
        {/* <Title level={3}>Published recently</Title>
        <Text>Coming Soon</Text>

        <Divider /> */}
        
        <Title level={3}>Randomly selected</Title>
        <Items key={"r" + randomNum} url={"https://random-selector.fullstackdata.workers.dev"}/>

      </Content>
    </Layout>
  );
}

export default Main;
