import React, {useState, useEffect} from 'react';
import { Layout, Typography, Image, Affix, Button, Row, Col  } from 'antd';
import NavBar from './Components/NavBar';
import Items from './Components/Items';
import { LikeOutlined, DislikeOutlined} from '@ant-design/icons';
import Analytics from './Utilities/Analytics'
import "./App.css";

function URL(props) {
  const { Content } = Layout;
  const { Title } = Typography;
  const pagePath = decodeURIComponent(props.match.params.pagePath);

  const [data, setData] = useState({title: "", html: "", leadImgUrl: ""});
  const getData = async() => {
    const urlToFetch = "https://personalize.fullstackdata.workers.dev/get_content";
    const response = await fetch(urlToFetch, {
      method: "POST",
      body: JSON.stringify({
        reqType: "content_related",
        contentId: pagePath
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const data = await response.json();
    setData(data);
  };

  useEffect(() => {
    getData();
    const body = document.querySelector('#root');

    body.scrollIntoView({
      behavior: 'smooth'
    }, 500);
  }, [pagePath]);
  
  return (
    <Layout className="layout" style={{"maxWidth": 500, "margin": "0 auto", "height": "100%"}}>
      <NavBar />
      <Content style={{ padding: '10px' }}>
        <Image
          src={data.leadImgUrl}
          fallback="https://www.renaza.com/wp-content/uploads/2016/04/dummy-post-horisontal.jpg"
        />
        <Title level={3}>{data.title}</Title>
        <div dangerouslySetInnerHTML={{ __html: data.html }} />
        <Title level={3}>Read Next</Title>
        <Items url={"https://personalize.fullstackdata.workers.dev/get_similar"} options={{reqType: "content_related", contentId: pagePath}} key={pagePath} />
        <Affix style={{position:'fixed', bottom: 0, left: "50%", transform: "translate(-50%, -50%)"}}>
          <Row>
            <Col span={12}>
              <Button type="primary">
                <LikeOutlined />
              </Button>
            </Col>
            <Col span={12}>
              <Button danger>
                <DislikeOutlined />
              </Button>
            </Col>
          </Row>
        </Affix>
      </Content>
      <Analytics key={pagePath} />
    </Layout>
  );
}

export default URL;
