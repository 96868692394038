import React from 'react'
import { Card, Avatar } from 'antd';
import { Link } from "react-router-dom"

const { Meta } = Card;

const Item = (props) => {
  return (
    <Card style={{ marginTop: 16 }} >
      <Meta
        avatar={
          <Avatar src={props.leadImgUrl} />
        }
        title={
          <Link to={"/url/" + encodeURIComponent(props.url)}>{props.title}</Link>
        }
      />
    </Card>
  )
}

export default Item
